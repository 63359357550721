import { Container } from "./styles";
import githubIcon from "../../assets/github.svg"
// import DownloadApp from '../../assets/download.png'
import externalLink from "../../assets/external-link.svg"
import ScrollAnimation from "react-animate-on-scroll";


export function Project() {
  return (
    <Container id="project">
      <h2>My Projects</h2>
      <div className="projects">

        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <svg width="50" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="#23ce6b" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"> <title>Folder</title> <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path> </svg>
              <div className="project-links">
                <a href="https://github.com/CodeVinayak/Serverless-Voting-Application" target="_blank" rel="noreferrer">
                  <img src={githubIcon} alt="Visit site" /></a>
                <a href="https://vote.vinayaksingh.com" target="_blank" rel="noreferrer">
                  <img src={externalLink} alt="Visit site" />
                </a> </div>
            </header>
            <div className="body">
              <h3>Custom ERP and More Web Application</h3>
              <p>Designed and developed a comprehensive ERP solution tailored to streamline and optimize various critical functions for a manufacturing business. This custom ERP platform centralizes essential operations, including:</p>
   
              <ul>
      <li><strong>Purchase Orders & Fabrication Orders:</strong> Facilitates the seamless creation, management, and tracking of orders across departments.</li>
      <li><strong>Internal PDF Processing & Reporting Tools:</strong> Streamlines document generation, processing, and report generation with custom PDF tools.</li>
      <li><strong>Time Tracking & Material Library:</strong> Tracks time for project accuracy and provides an accessible material library for quick reference.</li>
      <li><strong>Stock Cutting Optimizer:</strong> Optimizes material cutting, ensuring minimal waste and efficient use of stock materials.</li>
      <li><strong>Customer & Vendor Management:</strong> Manages relationships, transactions, and interactions with both customers and vendors.</li>
      <li><strong>Request for Quotes (RFQ) Tracking:</strong> Simplifies RFQ processes and maintains a record for effective tracking.</li>
      <li><strong>Data Processing Mini Tools:</strong> Reduces manual tasks with various tools to save time and improve workflow efficiency across business functions.</li>
    </ul>
    <p>This ERP solution offers an intuitive interface and is designed to scale as business needs grow, providing a solid foundation for efficient, accurate, and data-driven operations.</p>
 
            </div>
            <footer> <ul className="tech-list">
              <li>PHP</li>
                <li>HTML</li>
                <li>CSS</li>
                <li>MySQL</li> </ul> </footer>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <svg width="50" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="#23ce6b " strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"><title>Folder</title> <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path> </svg>
              <div className="project-links">
                <a href="https://optim-50a60.firebaseapp.com/" target="_blank" rel="noreferrer">
                  <img src={externalLink} alt="Visit site" /></a>
              </div>
            </header>
            <div className="body">
              <h3>Cut Optimizing Web App</h3>
              <p>Developed a specialized cut optimizing software tailored to meet the unique requirements of a specific industry. This software was designed to maximize material efficiency, reducing waste and enhancing productivity.</p>
    <p>The software was successfully sold to industry consumers, providing them with an advanced tool for precise cutting plans and optimized material usage to drive down costs and improve operational efficiency.</p>
  
            </div>
            <footer>
              <ul className="tech-list">
                <li>React</li>
                <li>Node.js</li>
                <li>Typescript</li>
                <li>Stripe API</li>
                <li>Firebase</li>
                <li>Genectic Mutation Algorithm</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <svg width="50" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="#23ce6b" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round">
                <title>Folder</title>
                <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
              </svg>
              <div className="project-links">
                <a href="https://peterpenthouse.com/Images/Customer_Portal.gif" target="\_blank" rel="noreferrer">
                  <img src={externalLink} alt="Visit site" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>Customer Approval Web App</h3>
              <p>Built a customer portal to streamline client interactions, providing customers with easy access to necessary reports, as well as options to respond, approve, comment, and track their requests in real-time.</p>
    <p>This portal enhanced communication, simplified approvals, and allowed for more efficient, transparent project management, meeting both client and business needs effectively.</p>
            </div>
            <footer>
              <ul className="tech-list">
              <li>React</li>
              <li>Typescript</li>
              <li>PDF.js</li>
              <li>PDF Encoding</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <svg width="50" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="#23ce6b " stroke-width="1" stroke-linecap="round" stroke-linejoin="round" ><title>Folder</title><path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path></svg>
              <div className="project-links">
                <a href="https://github.com/terrible-terry/SHX-Acrobat-Batch-Linker-main" target="_blank" rel="noreferrer">
                  <img src={githubIcon} alt="Visit site" />
                </a>
              </div>
            </header>
            <div className="body">
            <h3>Adobe Acrobat Navigation Plugin</h3>
            <p>Created an Adobe Acrobat plugin that automates page linking, allowing users to navigate directly to relevant pages via callouts. This feature enhances document navigation, making it seamless and efficient, especially for documents with extensive cross-references.</p>
    <p>This plugin improves usability and reduces manual linking efforts, delivering a smooth experience for users needing quick access to specific content within complex PDFs.</p>
</div>
            <footer>
              <ul className="tech-list">
                <li>Acrobat JS</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <svg width="50" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="#23ce6b" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round">
                <title>Code</title>
                <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
              </svg>
              <div className="project-links">
                <a href="https://github.com/terrible-terry/CNC-Works" target="_blank" rel="noreferrer">
                  <img src={githubIcon} alt="Visit site" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>CNC Machining & Software Expertise</h3>
              <p>Experienced in CNC machining and programming with hands-on proficiency in 7000 and 5000 series Multicam 3-axis CNC machines, CNC saws like the Elumatec DG244, and experience with a 5-axis Tormach CNC and commercial 3D printers.</p>
              </div>
            <footer>
              <ul className="tech-list">
                <li>AutoCAD</li>
                <li>Inventor</li>
                <li>SolidWorks</li>
                <li>Solid Edge</li>
                <li>En-route</li>
                <li>Aspire</li>
                <li>eluCad</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>
        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <svg width="50" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="#23ce6b" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round">
                <title>Code</title>
                <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
              </svg>
            </header>
            <div className="body">
              <h3>Architectural & Fabrication Drawings</h3>
              <p>Experienced in creating detailed architectural and fabrication drawings, along with managing construction projects from inception to completion. This includes developing plans, submitting them to municipalities for approval, and overseeing the entire construction process.</p>
              <p>Here are some examples of my work:</p>   <ul>

      <li><a href="https://peterpenthouse.com/Resumes/Terry-Taylor/images/Vision%20Maker%20Tenant%20Build.pdf" target="_blank">Vision Maker Construction drawings</a></li>
      <li><a href="https://peterpenthouse.com/Resumes/Terry-Taylor/images/DPI%20Tenant%20Finish.pdf" target="_blank">DPI Construction drawings</a></li>
      <li><a href="https://peterpenthouse.com/Resumes/Terry-Taylor/images/El%20Rancho%20Grande%20Build.pdf" target="_blank">El Rancho Grande Construction drawings</a></li>

      <li><a href="https://peterpenthouse.com/Resumes/Terry-Taylor/images/Part%20Examples.pdf" target="_blank">Fabrication Drawings</a></li>
    </ul> </div>

            <footer>
              <ul className="tech-list">
                <li>AutoCAD</li>
                <li>Gantt Charts</li>
                <li>MS Office</li>
                <li>ProCore</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>
      

      </div>
    </Container>
  );
}